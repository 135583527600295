<template>
    <div>
        <section id="hero" class="hero-fullwidth parallax" data-overlay-dark="6">
            <div class="background-image">
                <img src="img/backgrounds/_A2A2899.jpg" alt="#">
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-md-12 text-center">
                        <h1><strong>Our Clients</strong></h1>
                        <p class="lead">Here, We make almost every genre of applications. You name it and we build it.</p>
                    </div>

                </div>
            </div>
        </section>
        <!-- End Hero -->

        <section class="pt70 pb70">
            <div class="container">
                <div class="row">
                    <div class="clients-slider" data-autoplay="false" data-speed="4000">
                         <div><img src="img/clients/maxmalipo.png" class=" " alt="#"></div>
                            <div><img src="img/clients/selcom.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/azampay.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/airtel.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/tigo.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/nmb.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/maendeleo.png" class="img-responsive" alt="#"></div>
                            <!-- <div><img src="img/clients/lgas.png" class="img-responsive" alt="#"></div> -->
                            <div><img src="img/clients/dse.png" class="img-responsive" alt="#"></div>
                              <!-- <div><img src="img/clients/tra.png" class=" " alt="#"></div> -->
                            <div><img src="img/clients/esrf.png" class="img-responsive" alt="#"></div>
                            <!-- <div><img src="img/clients/tcra.png" class="img-responsive" alt="#"></div> -->
                            <div><img src="img/clients/ethics.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/tanesco.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/jti.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/royal danish.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/drc.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/ais.png" class="img-responsive" alt="#"></div>
                              <div><img src="img/clients/pact.png" class=" " alt="#"></div>
                            <div><img src="img/clients/indigo.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/aln.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/dayone.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/pspf.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/costech.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/tcu.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/veta.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/polisi.png" class="img-responsive" alt="#"></div>
                              <div><img src="img/clients/ifm.png" class=" " alt="#"></div>
                            <div><img src="img/clients/utt.png" class="img-responsive" alt="#"></div>
                            
                    </div>
                </div>
            </div>
        </section>

        <section>
        </section>

      <Footer/>      
    </div>
</template>
<script>
import Footer from './home/footer.vue'
export default {
  components: {
    Footer
  }
}
</script>