<template>
    <div>
        <section id="hero" class="hero-fullwidth parallax" data-overlay-dark="6">
            <div class="background-image">
              <vue-particles
                class="particles-js"
                color="#ffffff"
                :particleOpacity="0.7"
                linesColor="#ffffff"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="5"
                :linesWidth="2"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="6"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
              >
              </vue-particles>               
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-md-12 text-center">
                        <h1>Who <strong>We</strong> Are</h1>
                        <p class="lead">Innovation with Heart</p>
                    </div>

                </div>
            </div>
        </section>


        <!-- Portfolio -->
        <section id="about" class="pt100 pb90 blog">
            <div class="container">
              <div class="row">
                  <div class="col-md-12 text-center pb20">
                      <h3><strong>ABOUT US</strong></h3>
                      <p class="lead">Leveraging advanced technologies to deliver scallable solutions
                          <br>that exceed expectations.</p>
                  </div>

                    <div class="col-md-12">
                        <ul class="blog-list">
                            <li class="row vertical-align">
                                <div class="col-md-6 mt20 mb20">
                                    <a href="#"><img src="img/backgrounds/team.jpg" class="img-responsive width100" alt="#"></a>
                                </div>
                                <div class="col-md-6">
                                    <div class="post-header">
                                        <h4><strong>Our Story</strong></h4>
                                    </div>
                                    <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>

                                    
                                        <h4><strong><b>In Software Development</b></strong></h4>
                                    

                            <p>We address the evolving social and business technology challenges by defining, designing and developing applications tailored to meet our community’s requirements.</p>

                                    
                                        <h4><strong><b>In Cyber Security</b></strong></h4>
                                    

                            <p>We provide comprehensive penetration testing, vulnerability assessment, malware analysis and cryptography services. Our state-of-the-art Cybersecurity Lab applies current research results to ensure the best protection of our products and clients.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                
                 </div>
            </div>
        </section>

        <section id="who-we-are" class="parallax pt40 pb40" data-overlay-dark="8">
            <div class="background-image">
                <img src="img/gif/J4o.gif" alt="#">
            </div>
            <div class="container">
                <div class="row vertical-align">
                    <div class="col-md-6 pr30 mt40 mb40">
                        <h3><strong>STAYING TRUE TO</strong>
                            <br><hr>
                            <strong>OUR CORE VALUES</strong>
                        </h3>
                    </div>

                    <div class="col-md-9 pt40 pb30">
                        <div>
                            <h4><strong>Quality</strong></h4>
                            <p>We deliver high quality, scalable and secure solutions to serve your business objectives. </p>
                        </div>
                        <div>
                            <h4><strong>Value for money</strong></h4>
                            <p>Our cutting-edge software solutions help organizations to streamline their business operation, reduce cost, enable automation in process and increase revenue.</p>
                        </div>
                        <div>
                            <h4><strong>Timely</strong></h4>
                            <p>Transparent project management with maximum adherence to deadlines.</p>
                        </div>                                                
                    </div>
                </div>
            </div>
        </section>



        <section id="services" class="pt100 pb110 bg-grey">
            <div class="container">
                <div class="row text-center">
                  <div class="col-md-12 text-center">
                      <h3><strong>OUR MAIN AREAS OF FOCUS</strong></h3>
                      <!--<p class="lead">In Software development and cyber security is where we stand as Magilatech.</p>-->
                  </div>
                  <br>
                  <br>

                    <div class="img-card" data-gap="20">
                        <div>
                            <!-- Portfolio Item -->
                            <div class="col-md-6 col-sm-6">
                                <a href="/software">
                                    <div class="img-card-item">
                                        <div class="item-caption">
                                            <h4>Software Development</h4>
                                            <p>We offer a wide range of customized services</p>
                                             <p>in mobile apps, web apps, enterprise application, AR-VR development and much more.</p>
                                        </div>
                                        <div class="item-image"> <img alt="#" src="img/services/software.jpg" /> </div>
                                    </div>
                                </a>
                            </div>
                            <!-- Portfolio Item -->
                            <div class="col-md-6 col-sm-6">
                                <a href="/cyber">
                                    <div class="img-card-item">
                                        <div class="item-caption">
                                            <h4>Cyber Security</h4>
                                            <p>We are offering a comprehensive range of cyber security services</p>
                                            <p>To help organizations protect their valuable assets.</p>
                                        </div>
                                        <div class="item-image"> <img alt="#" src="img/services/cyber.jpg" /> </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section class="parallax pt100 pb100" data-overlay-dark="6">
            <div class="background-image"> <img src="img/backgrounds/award3.jpg" alt="#"> </div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-8 mr-auto text-center">
                        <h2>Our Recognition's, <br><strong>Achievements & Awards</strong></h2>
                        <p class="lead"></p>
                    </div>
                    <div class="col-md-12">
                        <ul class="connected-icons social-icons">
                            <li class="connected-icon">
                                <a target="_blank" href="#"> <i class="ion-trophy"></i>
                                    <h5>Top 100 Mid-sized</h5>
                                    <p>2018/2019 (No.4)</p>
                                </a>
                            </li>
                            <li class="connected-icon">
                                <a target="_blank" href="#"> <i class="ion-trophy"></i>
                                    <h5>AABLA Innovator of the Year</h5>
                                    <p>East Africa (2017)</p>
                                </a>
                            </li>
                            <li class="connected-icon">
                                <a target="_blank" href="#"> <i class="ion-trophy"></i>
                                    <h5>Global Recognition of Excellence</h5>
                                    <p>ITU Hungary (2015)</p>
                                </a>
                            </li>
                            <li class="connected-icon">
                                <a target="_blank" href="#"> <i class="ion-trophy"></i>
                                    <h5>Innovation Fund award for best app</h5>
                                    <p>Mparliament (2014)</p>
                                </a>
                            </li>
                            <li class="connected-icon">
                                <a target="_blank" href="#"> <i class="ion-trophy"></i>
                                    <h5>Start-up World Africa+</h5>
                                    <p>Top ten Finalist (2012)</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section id="elements-testimonials" class="pt100 pb100">
            <div class="container">
                <div class="row">

                    <div class="col-md-12 text-center pb20">
                        <h3><strong>OUR CLIENTS</strong></h3>
                    </div>

                    <div class="col-md-12 text-center">
                        <div class="testimonials quote-icons dark-pagination">
                            <div>
                                <p>
                                    <i class="vossen-quote color"></i>
                                    Tigo backup application has greatly helped our customers backup data through their mobile phones, I can’t say enough about how well it is tailored to the needs of TIGO customers. It is definitely one of the best mobile apps that we have ever purchased for a long time. Thank you Magilatech it has been an honor working with you.
                                    <i class="vossen-quote color"></i>
                                </p>
                                <h5><strong>TIGO</strong></h5>
                                <p class="occupation">CEO</p>
                            </div>
                            <!-- Testimonial Two -->
                            <div>
                                <p>
                                    <i class="vossen-quote color"></i>
                                   Your work on our Airtel Vsomo mobile app has made a significant difference to  Airtel, VETA, and Tanzania at large. We’ve seen a tremendous increase in students learning through the application which has been pretty remarkable and truly exceptional! Well done Magilatech, you’re truly a life changer.
                                    <i class="vossen-quote color"></i>
                                </p>
                                <h5><strong>AIRTEL</strong></h5>
                                <p class="occupation">CEO</p>
                            </div>
                            

                        </div>
                    </div>

                    <div class="col-md-12 mt20 mb40">
                        <div class="clients-slider" data-autoplay="true" data-speed="2000">
                          <div><img src="img/clients/maxmalipo.png" class=" " alt="#"></div>
                            <div><img src="img/clients/selcom.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/azampay.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/airtel.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/tigo.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/nmb.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/maendeleo.png" class="img-responsive" alt="#"></div>
                            <!-- <div><img src="img/clients/lgas.png" class="img-responsive" alt="#"></div> -->
                            <div><img src="img/clients/dse.png" class="img-responsive" alt="#"></div>
                              <!-- <div><img src="img/clients/tra.png" class=" " alt="#"></div> -->
                            <div><img src="img/clients/esrf.png" class="img-responsive" alt="#"></div>
                            <!-- <div><img src="img/clients/tcra.png" class="img-responsive" alt="#"></div> -->
                            <div><img src="img/clients/ethics.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/tanesco.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/jti.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/royal danish.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/drc.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/ais.png" class="img-responsive" alt="#"></div>
                              <div><img src="img/clients/pact.png" class=" " alt="#"></div>
                            <div><img src="img/clients/indigo.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/aln.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/dayone.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/pspf.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/costech.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/tcu.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/veta.png" class="img-responsive" alt="#"></div>
                            <div><img src="img/clients/polisi.png" class="img-responsive" alt="#"></div>
                              <div><img src="img/clients/ifm.png" class=" " alt="#"></div>
                            <div><img src="img/clients/utt.png" class="img-responsive" alt="#"></div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from './home/footer.vue'
export default {
  components: {
    Footer
  }
}
</script>